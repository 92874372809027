
import { get, post } from "@/axios";


export function getOrderList(data) {
  return get('/api/email_list/list', data);
}





//批量分配
export function set_status(data) {
  return post('/api/transactions/set_status', data);
}

export function orderDelete(data) {
  return post('/api/email_list/delete', data);
}
//搜索订单
export function search_order(data) {
  return get('/api/transactions/search_order', data);
}
//关联订单
export function to_association(data) {
  return post('/api/email_list/to_association_id', data);
}
//回复邮件
export function send_email(data) {
  return post('/api/email_list/send_email', data);
}
//webSocket
export function socketBind(data) {
  return post('/api/socket/bind', data);
}

//修改邮箱
export function to_association_email(data) {
  return post('/api/email_list/to_association_email', data);
}

//修改产品数量
export function update_products(data) {
  return post('/api/transactions/update_products', data);
}

//修改地址电话名称
export function update_address(data) {
  return post('/api/transactions/update_customer_info', data);
}

//保存草稿
export function save_email_draft(data) {
  return post('/api/email_draft/update', data);
}

//获取草稿
export function get_email_draft(data) {
  return get('/api/email_draft/saved', data);
}
// 邮件不处理
export function set_replied_status(data) {
  return post('/api/email_list/set_replied_status', data);
}