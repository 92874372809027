import Vue from 'vue'
import Router from 'vue-router'

import layout from '@/components/layout'
import Login from '@/views/login/';
import store from '@/store';
import { getCookie } from '@/static/js/cookie';

Vue.use(Router)
const projectName = process.env.VUE_APP_ProjectName;

export const constantRouterMap = [
  {
    path: '/login',
    component: Login
  },
]
let router = new Router({
  routes: constantRouterMap,

});

export const asyncRouterMap = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    meta: {
      title: '首页',
      icon: 'dashboard',
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: resolve => require(['@/views/home/index'], resolve),
        meta: {
          title: '邮件管理',
          icon: 'apartment',
        },
        hidden: true,

      },
      {
        path: '/recycle',
        name: 'recycle',
        component: resolve => require(['@/views/recycle/index'], resolve),
        meta: {
          title: '回收站',
          icon: 'delete',
          roles: "super",
        },
        hidden: true,

      },
      {
        path: '/order',
        name: 'order',
        component: resolve => require(['@/views/order/index'], resolve),
        meta: {
          title: '订单管理',
          icon: 'shopping-cart',
        },
        hidden: true,

      },
      {
        path: '/cards',
        name: 'cards',
        component: resolve => require(['@/views/cards/index'], resolve),
        meta: {
          title: '卡号管理',
          icon: 'credit-card',
          roles: "super",
        },
        hidden: true,

      },
      {
        path: '/email',
        name: 'email',
        component: resolve => require(['@/views/email/index'], resolve),
        meta: {
          title: '邮箱配置',
          icon: 'mail',
          roles: "super",
        },
        hidden: true,

      },
      {
        path: '/blacklist',
        name: 'blacklist',
        component: resolve => require(['@/views/blacklist/index'], resolve),
        meta: {
          title: '黑名单管理',
          icon: 'minus-circle',
          roles: "super",
        },
        hidden: true,

      },

      // {
      //   path: '/order',
      //   name: 'order',
      //   component: resolve => require(['@/views/order/index'],resolve),
      //   meta:{
      //     title: '订单管理',
      //     icon: 'pay-circle',
      //     roles:"super",
      //   },
      //   hidden:true,
      // },
      {
        path: '/user',
        name: 'user',
        component: resolve => require(['@/views/user/index'], resolve),
        meta: {
          title: '用户设置',
          icon: 'user',
          roles: "super",
        },
        hidden: true,

      },
      // {
      //   path: '/stat',
      //   name: 'stat',
      //   component: resolve => require(['@/views/stat/index'],resolve),
      //   meta:{
      //     title: '催单统计',
      //     icon: 'user',
      //     roles:"super",
      //   },
      //   hidden:true,

      // },
      {
        path: '/log',
        name: 'log',
        component: resolve => require(['@/views/log/index'], resolve),
        meta: {
          title: '日志管理',
          icon: 'issues-close',
          roles: "super",
        },
        hidden: true,
        children: [
          {
            path: '/log/error_log',
            name: 'error_log',
            component: resolve => require(['@/views/log/error_log'], resolve),
            meta: {
              title: '错误日志',
              icon: 'issues-close',
              roles: "super",
            },
            hidden: true,

          },
          {
            path: '/log/action_log',
            name: 'action_log',
            component: resolve => require(['@/views/log/action_log'], resolve),
            meta: {
              title: '行为日志',
              icon: 'issues-close',
              roles: "super",
            },
            hidden: true,

          },
        ]
      },

    ]
  },
  { path: '/lockscreen', component: resolve => require(['@/views/err/lockscreen'], resolve), },
  { path: '*', component: resolve => require(['@/views/err/404'], resolve), }
];

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const whiteList = ['/login', '/Printing']// 白名单
router.beforeEach((to, from, next) => {
  if (sessionStorage.getItem('isLock') && to.path !== '/lockscreen') {//如果有锁屏，就退出本次导航
    console.log(sessionStorage.getItem('isLock'), '导航守卫取值')
    next({ path: '/lockscreen' })
  } else {
    let token = getCookie("token");
    if (token) { // 判断是否有token
      /* has token*/
      if (!store.getters.roles) { // 判断当前用户是否已拉取完user_info信息
        // console.log(token,'token')
        let role = JSON.parse(token).role
        if (role) {
          store.commit('SET_ROLES', role)
          store.dispatch('GenerateRoutes', role).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,设置replace:true，这样导航就不会留下历史记录
          })
        } else {
          console.log('获取role失败')
          store.dispatch('LogOut').then(() => {
            next({ path: '/login' })
          })
        }

      } else {
        next()
      }

    } else {

      /*没有token的话，判断是否在白名单中，是就进入，不是就跳转到登录页面*/
      if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
        next()
      } else {
        next(`/login?url=${to.path}`) // 否则全部重定向到登录页
      }
    }

    next()//锁屏结束
  }
})

router.afterEach((to) => {//from参数暂时用不到
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + projectName
  }
})
export default router
